<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        :title="
          'ข้อมูลผู้สมัครสอบตำแหน่ง รองผู้อำนวยการ ครั้งที่ : ' +
            period_vecprovinces.period_vecprovince_times +
            ' ปี : ' +
            period_vecprovinces.period_vecprovince_year
        "
      >
        <v-card class="mb-4 pa-2">
          <v-row no-gutters>
            <v-col cols="12" md="9" class="text-right pa-1">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>

            <v-col cols="12" md="3" class="text-right pa-1">
              <v-btn block rounded color="info" @click="searchTimeYear()"
                ><v-icon>mdi-account-search</v-icon>ค้นหา</v-btn
              >
              <v-btn
                block
                rounded
                color="success"
                @click="showSuccessPosition()"
                ><v-icon>mdi-account-search</v-icon>
                รายการที่สำเร็จแล้ว
              </v-btn>
              <v-btn
                block
                rounded
                color="info"
                :href="
                  '#/admin/print_assess1420/' +
                    period_vecprovinces.period_vecprovince_times +
                    '/' +
                    period_vecprovince_years
                "
                target="_blank"
                ><v-icon>mdi-printer</v-icon>ส่งออกข้อมูลผู้สมัคร
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
        <v-select
          v-model="selectedHeaders"
          :items="headers"
          label="เลือกคอลัมน์ที่ต้องการแสดง"
          multiple
          outlined
          return-object
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 6">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 6" class="grey--text caption"
              >(+{{ selectedHeaders.length - 6 }} คอลัมน์)</span
            >
          </template>
        </v-select>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="showHeaders"
          :items="sedirector_apps"
          :search="search"
          item-key="sedirector_app_idref"
        >
          <template v-slot:item.sedirector_app_status="{ item }">
            <v-chip color="success" v-if="item.sedirector_app_status >= 7">
              บันทึก
            </v-chip>
            <v-chip
              @click="
                changeStatus(
                  item.sedirector_app_year,
                  item.sedirector_app_time,
                  item.sedirector_app_idcard
                )
              "
              color="warning"
              v-else
            >
              ยังไม่ได้บันทึก
            </v-chip>
          </template>

          <template v-slot:item.sedirector_app_position="{ item }">
            <v-chip
              color="success"
              v-if="item.sedirector_app_position === 'teach'"
            >
              ครูผู้สอน</v-chip
            >
            <v-chip
              color="success"
              v-else-if="item.sedirector_app_position === 'head'"
            >
              หัวหน้างาน</v-chip
            >
            <v-chip
              color="success"
              v-else-if="item.sedirector_app_position === 'director'"
            >
              รักษาการรองผู้อำนวยการ</v-chip
            >
            <v-chip
              @click="
                changeStatus(
                  item.sedirector_app_year,
                  item.sedirector_app_time,
                  item.sedirector_app_idcard
                )
              "
              color="warning"
              v-else
            >
              ยังไม่ได้บันทึก
            </v-chip>
          </template>

          <template v-slot:item.sedirector_app_discipline="{ item }">
            <v-chip
              color="warning"
              v-if="item.sedirector_app_discipline === 'ever'"
            >
              เคยถูกลงโทษทางวินัย
            </v-chip>
            <v-chip
              @click="
                changeStatus(
                  item.sedirector_app_year,
                  item.sedirector_app_time,
                  item.sedirector_app_idcard
                )
              "
              color="success"
              v-else
            >
              ไม่เคยถูกลงโทษทางวินัย
            </v-chip>
          </template>

          <template v-slot:item.sedirector_app_time="{ item }">
            {{ item.sedirector_app_time + "/" + item.sedirector_app_year }}
          </template>

          <template v-slot:item.sedirector_app_file="{ item }">
            <div v-if="item.sedirector_app_file">
              <div>
                <v-icon
                  color="red"
                  large
                  v-if="item.sedirector_app_file_cc === 'cc'"
                  >mdi-information</v-icon
                >
                <v-btn
                  large
                  text
                  rounded
                  color="green"
                  @click="pdfHrvecRegisDir(item.sedirector_app_file)"
                  ><v-icon large>mdi-printer</v-icon></v-btn
                >
                <v-icon
                  large
                  color="red"
                  @click.stop="CancelFilePDF(item.sedirector_app_idref)"
                  >mdi-delete-circle</v-icon
                >
              </div>
            </div>
            <div v-else>
              <v-chip dark color="warning">
                ไม่ได้แนบไฟล์
              </v-chip>
            </div>
          </template>

          <template v-slot:item.sedirector_app_file_A="{ item }">
            <div v-if="item.sedirector_app_file_A">
              <v-icon
                color="red"
                large
                v-if="item.sedirector_app_file_A_CC === 'cc'"
                >mdi-information</v-icon
              >
              <v-btn
                text
                rounded
                color="green"
                large
                @click="pdfHrvecRegisDir(item.sedirector_app_file_A)"
                ><v-icon large>mdi-printer</v-icon></v-btn
              >
              <v-icon
                color="red"
                large
                @click.stop="CancelFileAPDF(item.sedirector_app_idref)"
                >mdi-delete-circle</v-icon
              >
            </div>
            <div v-else>
              <v-chip dark color="warning">
                ไม่ได้แนบไฟล์
              </v-chip>
            </div>
          </template>

          <template v-slot:item.sedirector_app_file_B="{ item }">
            <div v-if="item.sedirector_app_file_B">
              <v-icon
                color="red"
                large
                v-if="item.sedirector_app_file_B_CC === 'cc'"
                >mdi-information</v-icon
              >
              <v-btn
                text
                rounded
                color="green"
                large
                @click="pdfHrvecRegisDir(item.sedirector_app_file_B)"
                ><v-icon large>mdi-printer</v-icon></v-btn
              >
              <v-icon
                color="red"
                large
                @click.stop="CancelFileBPDF(item.sedirector_app_idref)"
                >mdi-delete-circle</v-icon
              >
            </div>
            <div v-else>
              <v-chip dark color="warning">
                ไม่ได้แนบไฟล์
              </v-chip>
            </div>
          </template>

          <template v-slot:item.sedirector_app_datetime="{ item }">
            {{
              item.sedirector_app_datetime
                | moment("add", "543 years")
                | moment("D MMMM YYYY : h:mm:ss a")
            }}
          </template>

          <template v-slot:item.sedirector_app_pvc_approve="{ item }">
            <v-btn
              v-if="item.sedirector_app_status === 'success'"
              @click="
                sedirector_app_pvc_approveUdate(item.sedirector_app_idref)
              "
              rounded
              text
              color="green"
              large
              ><v-icon>mdi-checkbox-marked-circle</v-icon></v-btn
            >
            <v-btn
              v-else
              @click="sedirector_app_pvc_approves(item.sedirector_app_idref)"
              rounded
              icon
              color="grey"
              large
              ><v-icon>mdi-checkbox-marked-circle</v-icon></v-btn
            >
          </template>

          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model CancelFilePDFDrdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="CancelFilePDFDrdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="ยกเลิกการแนบไฟล์"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="CancelFilePDFDrdialogform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>
                        รหัสอ้างอิง :
                        {{ sedirector_apps_id_ref.sedirector_app_idref }}
                        {{ sedirector_apps_id_ref.sedirector_app_year }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ sedirector_apps_id_ref.sedirector_app_idcard }}
                      <h3>
                        ชื่อ-นามสกุล :
                        {{ sedirector_apps_id_ref.title_s
                        }}{{ sedirector_apps_id_ref.frist_name }}
                        {{ sedirector_apps_id_ref.last_name }}
                        สถานศึกษาปัจจุบัน :
                        {{ sedirector_apps_id_ref.college_code }}
                        {{ sedirector_apps_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ sedirector_apps_id_ref.id_position }}
                      </h3>
                      <h4>
                        สมัครครั้งที่ :
                        {{ sedirector_apps_id_ref.sedirector_app_time }} ปี :
                        {{ this.sedirector_apps_id_ref.year_ss }}
                      </h4>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="CancelFilePDFDrdialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="CandelFilePDFSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยันยกเลิก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model approvepvcDrdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="approvepvcDrdialog" persistent max-width="60%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="ตรวจสอบเอกสาร"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="approvepvcDrdialogform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h3>
                        รหัสอ้างอิง :
                        {{ sedirector_apps_id_ref.sedirector_app_idref }}
                        {{ sedirector_apps_id_ref.sedirector_app_year }}
                      </h3>
                      รหัสบัตรประชาชน :
                      {{ sedirector_apps_id_ref.sedirector_app_idcard }}
                      <h3>
                        ชื่อ-นามสกุล :
                        {{ sedirector_apps_id_ref.title_s
                        }}{{ sedirector_apps_id_ref.frist_name }}
                        {{ sedirector_apps_id_ref.last_name }}
                        สถานศึกษาปัจจุบัน :
                        {{ sedirector_apps_id_ref.college_code }}
                        {{ sedirector_apps_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ sedirector_apps_id_ref.id_position }}
                      </h3>
                      <h4>
                        สมัครครั้งที่ :
                        {{ sedirector_apps_id_ref.sedirector_app_time }} ปี :
                        {{ this.sedirector_apps_id_ref.year_ss }}
                      </h4>
                    </v-col>
                    <v-col cols="12" md="4">                      
                      ตรวจสอบเอกสารผู้สมัคร
                      <v-radio-group row v-model="sedirector_app_pvc_approve">
                        <v-radio value="fail">
                          <template v-slot:label>
                            <div>
                              <strong class="red--text"
                                >ไม่ผ่าน</strong
                              >
                            </div>
                          </template>
                        </v-radio>
                        <v-radio value="pass">
                          <template v-slot:label>
                            <div>
                              <strong class="primary--text"
                                >ผ่าน</strong
                              >
                            </div>
                          </template>
                        </v-radio>
                      </v-radio-group>
                    </v-col>

                    <v-col cols="12" md="8"
                      ><v-file-input
                        v-model="sedirector_app_filepvc"
                        accept=".pdf"
                        name="sedirector_app_filepvc"
                        color="deep-purple accent-4"
                        counter
                        label="ไฟล์ .pdf"
                        placeholder="หนังสือแจ้งการตรวจสอบเอกสาร"
                        prepend-icon="mdi-paperclip"
                        outlined
                        rounded
                        :rules="rules"
                        :show-size="1000"
                      >
                        <template v-slot:selection="{ index, text }">
                          <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                          >
                            {{ text }}
                          </v-chip>

                          <span
                            v-else-if="index === 2"
                            class="text-overline grey--text text--darken-3 mx-2"
                          >
                            +{{ files.length - 2 }} File(s)
                          </span>
                        </template>
                      </v-file-input></v-col
                    >
                  </v-row>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="approvepvcDrdialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="approvepvcSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;ยืนยันยกเลิก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pdfHrvecRegisDirdialog -->
      <v-layout row justify-center>
        <v-dialog v-model="pdfHrvecRegisDirdialog" max-width="60%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HrvecRegisDir/' + pdffiles"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>{{ snackbar.text }}</v-card-text>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import sedirector_app_bar from "../../components/admin/sedirector_app_bar.vue";

export default {
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      branch_s: [],
      sedirector_app_idref: [],
      valid: true,
      CancelFilePDFDrdialog: false,
      CancelFilePDFProvinceDrdialog: false,
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      sedirector_apps: [],
      search: "",
      pagination: {},
      singleSelect: false,
      selected: [],
      headers: [],
      selectedHeaders: [],
      headersMap: [
        { text: "จังหวัด", align: "center", value: "province_name" },
        {
          text: "รหัสบัตรประชาชน",
          align: "center",
          value: "sedirector_app_idcard"
        },
        { text: "สถานะ", align: "center", value: "sedirector_app_status" },
        { text: "คำนำหน้า", align: "left", value: "title_s" },
        { text: "ชื่อ", align: "left", value: "frist_name" },
        { text: "นามสกุล", align: "left", value: "last_name" },
        { text: "สถานศึกษาปัจจุบัน", align: "left", value: "college_name" },
        { text: "เลขที่ตำแหน่งเดิม", align: "left", value: "id_position" },
        { text: "ครั้งที่/ปี", align: "center", value: "sedirector_app_time" },
        {
          text: "อายุงาน ณ ปัจจุบัน",
          align: "center",
          value: "sedirector_app_agework"
        },
        {
          text: "ตำแหน่งที่ใช้สมัคร",
          align: "center",
          value: "sedirector_app_position"
        },
        { text: "อายุงาน", align: "center", value: "sedirector_age_position" },
        {
          text: "อายุงานในตำแหน่ง",
          align: "center",
          value: "sedirector_age_position_work5"
        },
        { text: "วินัย", align: "center", value: "sedirector_app_discipline" },
        {
          text: "ตรวจสอบเอกสาร",
          align: "center",
          value: "sedirector_app_pvc_approve"
        },

        { text: "ใบสมัครและเอกสารประกอบฯ", align: "center", value: "sedirector_app_file" },
        {
          text: "เอกสารภาค ก.",
          align: "center",
          value: "sedirector_app_file_A"
        },
        {
          text: "เอกสารภาค ข.",
          align: "center",
          value: "sedirector_app_file_B"
        },

        {
          text: "วันที่ทำรายการ",
          align: "center",
          value: "sedirector_app_datetime"
        }
      ],

      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      sedirector_apps_id_ref: [],
      period_vecprovinces: [],
      rules: [
        value => !value || value.size < 5242880 || "ไฟล์ไม่เกิน 5 MB / 5120 KB",
        value => !!value || ""
      ],
      users: [],
      pdfHrvecRegisDirdialog: false,
      pdffiles: "",
      approvepvcDrdialog: false,
      sedirector_app_filepvc: null,
      sedirector_app_pvc_approve:"pass",
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },
  async mounted() {
    await this.userQuery();
    await this.period_vecprovinceQuery();
    await this.sedirector_appQueryAll();
  },
  methods: {
    async pdfHrvecRegisDir(pdffile) {
      this.pdffiles = "";
      this.pdffiles = pdffile;
      this.pdfHrvecRegisDirdialog = true;
    },

    async userQuery() {
      let result;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      result = await this.$http.post("admin.php", {
        user_name: userSession.user_name,
        provice: "check",
        ApiKey: "HRvec2021"
      });
      this.users = result.data;
      
    },

    async CancelFilePDF(sedirector_app_idref) {
      let result_con = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        sedirector_app_idref: sedirector_app_idref
      });
      this.sedirector_apps_id_ref = result_con.data;
      this.CancelFilePDFDrdialog = true;
    },
    async CancelFilePDFProvinceDrdialogSubmit() {
      if (this.$refs.CancelFilePDFProvinceDrdialogform.validate()) {
        let result;
        this.sedirector_apps_id_ref.ApiKey = this.ApiKey;
        result = await this.$http.post("deletefile.php", {
          ApiKey: this.ApiKey,
          filename:
            "../HRvecfiles/" +
            this.sedirector_apps_id_ref.tpvecprovince_scoreFile
        });
        result = await this.$http.post(
          "sedirector_app.update.vp.php",
          this.sedirector_apps_id_ref
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.sedirector_appQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.CancelFilePDFProvinceDrdialog = false;
      }
    },
    async sedirector_app_pvc_approves(sedirector_app_idref) {
      let result_con = await this.$http.post("sedirector_app.php", {
        ApiKey: this.ApiKey,
        sedirector_app_idref: sedirector_app_idref
      });
      this.sedirector_apps_id_ref = result_con.data;   
      this.sedirector_app_filepvc=null
      this.approvepvcDrdialog = true;
    },
    async CandelFilePDFSubmit(sedirector_app_idref) {
      if (this.$refs.CancelFilePDFDrdialogform.validate()) {
        let result;
        this.sedirector_apps_id_ref.ApiKey = this.ApiKey;
        this.sedirector_apps_id_ref.sedirector_app_status = 7;

        result = await this.$http.post(
          "sedirector_app.update.php",
          this.sedirector_apps_id_ref
        );
        if (result.data.status == true) {
          Swal.fire({
            icon: "success",
            title: "ดำเนินการบันทึกข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          this.sedirector_appQueryAll();
        } else {
          Swal.fire({
            icon: "warning",
            title: "ดำเนินการผิดพลาด",
            showConfirmButton: false,
            timer: 1500
          });
        }
        this.CancelFilePDFDrdialog = false;
      }
    },

    async period_vecprovinceQuery() {
      let result_period_vecprovince;
      result_period_vecprovince = await this.$http.post(
        "period_vecprovince.php",
        {
          ApiKey: this.ApiKey,
          period_vecprovince_type: "verify_sedirectior",
          period_vecprovince_enable: "1"
        }
      );
      this.period_vecprovinces = result_period_vecprovince.data;
      let data = this.period_vecprovinces.period_vecprovince_enable;
      if (data != 1) {
        Swal.fire({
          icon: "error",
          title: "ระบบไม่เปิดใช้งาน",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          if (result.isConfirmed) {
            this.$router.push("/vecprovince");
          }
        });
      }
     
    },

    async sedirector_appQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("sedirector_app.php", {
          ApiKey: this.ApiKey,
          sedirector_app_time: this.period_vecprovinces
            .period_vecprovince_times,
          sedirector_app_year:
            parseInt(this.period_vecprovinces.period_vecprovince_year) + 543,
          province_ID: this.users.province_ID
        })
        .finally(() => (this.loading = false));
      this.sedirector_apps = result.data;
    },

    getColor(calories) {
      if (calories > 0) return "green";
      else return "";
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    time_stamp() {
      let time = Date.now();
      return time;
    },
    period_vecprovince_years() {
      let yyyy =
        parseInt(this.period_vecprovinces.period_vecprovince_year) + 543;
      return yyyy;
    },
    color() {
      return "lime darken-4";
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    }
  },
  components: { sedirector_app_bar }
};
</script>

<style scoped>
.orange {
  background-color: rgb(236, 194, 115);
}
</style>
